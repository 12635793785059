@import "reboot";

$gutter: 15px;
$main-color: #1C9ACF;

html, body, .app, #root {
    height: 100%;
}

body {
    overflow: hidden;
}

.app {
    display: flex;
    flex-direction: column;
}

.app-header {
    height: 40px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;

    h1 {
        color: #fff;
        font-weight: normal;
        font-size: 18px;
        margin: 0;
    }

    img {
        max-height: 40px;
        display: block;
    }
}

.app-body {
    padding-top: $gutter*2;
    flex: 1 1 auto;
    position: relative;/* need this to position inner content */
    overflow-y: auto;
    width: 1100px;
    @media (max-width: 767px) {
        max-width: 100%;
    }
    @media (min-width: 768px) {
        margin: 0 auto;
    }
}

.app-footer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    padding-top: 8px;
    padding-bottom: 8px;


    svg, span {
        display: block;
        opacity: .4;
        transition: opacity .2s ease-in-out;
    }

    .row {
        flex-direction: row !important;
        width: 100%;
        margin: 0;
    }

    .active {
        svg, span {
            opacity: 1;
        }
    }

    .col {
        display: flex;
        justify-content: center;

        div {
            display: flex;
            flex-direction: column;
            cursor: pointer;
        }
    }
}

.app-error {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        list-style: none;
        padding: 0;
        text-align: center;
    }

    h2 {
        font-size: 18px;
        text-align: center;
    }

    span {
        &.close {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 40px;
            opacity: .6;
            transition: opacity .2s ease-in-out;

            &:hover {
                opacity: .8;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.form-control {
    margin-bottom: 8px;

    label {
        display: block;
    }

    select {
        padding: 4px;
    }

    //@media (max-width: 767px) {
        margin-bottom: 20px;

        input, select, textarea {
            width: 100%;
        }
    //}
}

.has-gutter {
    padding-left: $gutter;
    padding-right: $gutter;
}

.row {
    display: flex;
    flex-direction: row;
    margin-left: -$gutter;
    margin-right: -$gutter;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.col {
    flex: 1 1;
    padding-left: $gutter;
    padding-right: $gutter;
}

.btn {
    background: $main-color;
    border: none;
    color: #fff;
    padding: 8px 16px;

    &:hover {
        cursor: pointer;
    }
}
